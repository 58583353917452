export interface IPlan extends IPlanInput {
  plan_id?: string;
  calculated?: IPlanCalculations[];
  actuals?: IPlanCalculations[];
}
export interface IPlanCalculations {
  month: number;
  date: string;
  data: IPlanValue[];
}
export interface IPlanValue {
  ratio: number;
  balance: number | null;
  starting_balance: number | null;
  balance_cumulated: number;
  global_code_id: string;
}

export interface ICopyPlanInput {
  title: string;
  description: string;
  active: boolean;
}
export interface IPlanInput extends ICopyPlanInput {
  susa_id: string;
  client_id: string;
  revenue_ratios: IRevenueRatios;
  revenue_growth: number;
  months: number;
}
export interface IRevenueRatios {
  jan: number;
  feb: number;
  mar: number;
  apr: number;
  may: number;
  jun: number;
  jul: number;
  aug: number;
  sep: number;
  oct: number;
  nov: number;
  dec: number;
}

export interface IPlanEntryItem {
  plan_id: string;
  active: boolean;
  title: string;
  description: string;
  months: number;
  start_month: string;
  created_at: string;
  income: boolean; //U
  personnel: boolean; //P
  costs: boolean; //K
  working_capital: boolean; //WC
  investment: boolean; //I
  financial: boolean; //F
  taxes: boolean; //S
}
export interface IPlanData {
  getPlans: undefined | IPlanEntryItem[];
}
export interface IPlanDataVariables {
  params: GetPlansInput;
}
export interface GetPlansInput {
  client_id: string;
  active?: boolean;
  with_data?: boolean;
}
export interface ICreatePlan {
  createPlan: IPlan;
}
export interface ICopyPlan {
  copyPlan: IPlan;
}
export interface IEditPlan {
  editPlan: IPlan;
}
export interface IDeletePlan {
  deletePlan: boolean;
}

export interface ICreatePlanVariables {
  plan: IPlanInput;
}
export interface ICopyPlanVariables {
  plan_id: string;
  plan: ICopyPlanInput;
}
export interface IEditPlanVariables {
  plan_id: string;
  plan: ICopyPlanInput;
}
export interface IDeletePlanVariables {
  plan_id: string;
}

export enum EUpdateCacheType {
  CREATE = "CREATE",
  EDIT = "EDIT",
  DUPLICATE = "DUPLICATE",
  REMOVE = "REMOVE",
  ACTIVATE = "ACTIVATE",
}
export interface IUpdateCacheBefore {
  type: EUpdateCacheType;
  plan_id: string;
  new_data?: ICopyFromPlan;
}

export interface IUpdateCacheAfter extends Omit<IUpdateCacheBefore, "new_data"> {
  new_data?: IPlan | null;
}
export type ICopyFromPlan = Pick<IPlan, "active" | "title" | "description" | "plan_id">;
