/* eslint no-template-curly-in-string: 0*/
const awsConfig = {
  env: {
    STAGE: "dev",
    PRODUCT: "tax",
    DOMAIN: "dev.canei.io",
  },
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://zzspp1otn9.execute-api.eu-central-1.amazonaws.com/dev/v1",
  },
  gqlGateway: {
    REGION: "eu-central-1",
    URL: "https://zzspp1otn9.execute-api.eu-central-1.amazonaws.com/dev/graphql",
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_mNDRloBL4",
    APP_CLIENT_ID: "3rg7fcs2ant5vnsjqo6o8ni703",
    IDENTITY_POOL_ID: "eu-central-1:74aa4efd-7a00-473e-9be1-68a23651b3dd",
  },
  i18n: {
    host: "locales.digital-dev-sb.dev.canei.io",
  },
  assets: {
    host: "assets.digital-dev-sb.dev.canei.io",
  },
  registration: {
    FORM_POST: "https://support.digital-dev-sb.dev.canei.io/v1/forms",
    NEXT_PAGE: "https://app.tax-dev-sb.dev.canei.io",
    TO_REFERRER: "https://canei.tax/register-success/",
  },
  payment: {
    publicKey: "pk_test_51Bo6NuEjjbFVZjn4S20X6Zjiu7W3kF7p2Cla974kKnAo5zowGaIxALu07W97xWFzyqcNvtLWAvno9ADlYegPApqI001Lc5dWNC",
  },
};

export default awsConfig;
